// The navbar

import {
    Box,
    Button,
    Collapse,
    Flex,
    Icon,
    IconButton,
    SlideFade,
    Text,
    VStack
} from '@chakra-ui/react';
import React, {
    useEffect,
    useState
} from 'react';

import { DownloadIcon } from '@chakra-ui/icons';
import { Spin as Hamburger } from 'hamburger-react'
import { Resume_PDF } from '../../assets/pdfs';

// Type definition for nav item
type NavItem = {
    name: string,
    type: 'Link',
    sectionId: string
} | {
    name: string,
    type: 'Button',
    onClick: () => void
};

const Navbar = () => {
    // State variable for mobile hamburger menu
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

    // State variable for Download CV button hover
    // ShowDownloadIcon becoming true triggers the download button's icon fade-in animation
    const [showDownloadIcon, setShowDownloadIcon] = useState(false);

    // State Variables for navbar animation
    // ShowNavbar becoming true triggers the navbar entry animation
    const [showNavbar, setShowNavbar] = useState(false);
    // ShowNavbarItems becoming true triggers the navbar items entry animations
    const [showNavbarItems, setShowNavbarItems] = useState(false);

    // Timeouts for navbar animation
    useEffect(() => {
        // Navbar enters after 1 second
        setTimeout(() => {
            setShowNavbar(true);
        }, 1000);

        // Navbar items enter after 2.8 seconds
        setTimeout(() => {
            setShowNavbarItems(true);
        }, 2800);
    }, []);

    // Scrolls to sections when navbar buttons are clicked
    const scrollToSection = (sectionId: string | null) => {
        // If no section id is given, scrolls to top
        if (sectionId === null) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            return;
        }

        const selectedSection = document.getElementById(sectionId);

        if (selectedSection) {
            window.scrollTo({
                top: selectedSection.offsetTop - 100,
                behavior: 'smooth'
            });
        }
    }

    // Processes navbar button click
    const onNavItemClick = (navItem: NavItem) => {
        if (navItem.type === 'Link') {
            scrollToSection(navItem.sectionId);
        } else {
            (navItem.onClick)();
        }

        // Closes mobile dropdown menu
        setTimeout(() => {
            setIsDropdownMenuOpen(false);
        }, 500);
    }

    // Nav items can be either a link (scroll to section) or button (do something on click)
    const NavItems: NavItem[] = [
        {
            name: 'About',
            type: 'Link',
            sectionId: 'AboutMeHomeSection'
        },
        {
            name: 'Experience & Projects',
            type: 'Link',
            sectionId: 'TimelineHomeSection'
        },
        {
            name: 'Contact Me',
            type: 'Link',
            sectionId: 'ContactMeHomeSection'
        }
    ];

    return (
        // A container is set as the fixed-position component to allow for animation of
        //      navbar and proper spacing with mobile dropdown menu.
        <VStack as="header" position="fixed" w='100%' mt={4} zIndex={999} spacing={2}>
            {/* 
                Navbar Animation:
                -------------------

                After a 1 second delay (see useEffect above), the navbar will fade in as
                a circle with height & width of 56px. 56px was chosen, since that is the 
                final height of the navbar once the navbar items are shown.

                After a 0.7s delay (after fade in), the navbar will begin to grow in width 
                until it is 80% of page.

                After a 2.8 second delay from page load (see useEffect above), the navbar 
                items will fade in while sliding in from bottom. They will slide-fade in
                one-by-one with a 0.1s delay between each.
            */}

            {/* Navbar */}
            <Flex ml='auto' mr='auto' w={showNavbar ? '80%' : '56px'} borderRadius="full"
                justifyContent="space-between" alignItems="center" maxH={'56px'}
                px={5} py={2} bg="#52A7E0" maxW={'1500px'}
                opacity={showNavbar ? 1 : 0} transition="opacity 0.5s, width 1.5s linear 0.7s"
            >
                {/* Left Side Logo */}
                <SlideFade in={showNavbarItems} offsetY={'20px'} delay={.5}>
                    <Button variant={'unstyled'}>
                        <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}
                            onClick={() => scrollToSection(null)}>
                            Andrew Lorber
                        </Text>
                    </Button>
                </SlideFade>

                {/* Right Side Nav Items */}
                <Flex alignItems="center" display={['none', null, null, 'block']}>
                    {/* Navbar Items */}
                    {NavItems.map((navItem, index) => (
                        <Button key={index} variant="ghost" mr={2} color={'#293241'}
                            onClick={() => { onNavItemClick(navItem) }}
                        >
                            <SlideFade in={showNavbarItems} offsetY={'20px'} delay={index * 0.1} >
                                {navItem.name}
                            </SlideFade>
                        </Button>
                    ))}

                    {/* Download CV Button */}
                    <Button borderRadius={'full'} borderColor={'#293241'} variant={'outline'} color={'#293241'}
                        rightIcon={showDownloadIcon ? <DownloadButtonIcon /> : undefined}
                        _hover={{ color: '#52A7E0', backgroundColor: '#293241', borderColor: '#52A7E0' }}
                        onMouseEnter={() => { setShowDownloadIcon(true) }} onMouseLeave={() => { setShowDownloadIcon(false) }}
                        w={showDownloadIcon ? '157px' : '133px'} opacity={showNavbarItems ? 1 : 0}
                        transition={`opacity 0.5s linear ${0.1 * NavItems.length}s, width 1s`}
                        onClick={() => { window.open(Resume_PDF, "_blank") }}
                    >
                        Download CV
                    </Button>
                </Flex>

                {/* Hamburger Menu for Mobile */}
                <Flex alignItems="center" display={['block', null, null, 'none']}>
                    <SlideFade in={showNavbarItems} offsetY={'20px'}>
                        <IconButton
                            icon={
                                <Hamburger toggled={isDropdownMenuOpen} toggle={setIsDropdownMenuOpen}
                                    direction="right" rounded size={28} />
                            }
                            variant="ghost"
                            aria-label='menu'
                        />
                    </SlideFade>
                </Flex>
            </Flex>

            {/* Dropdown menu for mobile navbar */}
            {/* 
                Navbar Animation:
                -------------------

                This uses Chakra UI's Collapse component to animate the dropdown menu
                opening downward and fading in.

                The Navbar items are all set to slide-fade in from top-down with a short
                delay between each.
            */}
            {/* Width is 75% so it matches nicely with the navbar's curve */}
            <Box w={'75%'} display={[null, null, null, 'none']}>
                <Collapse in={isDropdownMenuOpen} animateOpacity>
                    <VStack w={'100%'} borderRadius="2xl" justify="center"
                        px={5} py={1} bg="#52A7E0" spacing={1}
                    >
                        {/* Navbar Items */}
                        {NavItems.map((navItem, index) => (
                            <Button key={index} w={'100%'} variant="ghost" m={2}
                                onClick={() => { onNavItemClick(navItem) }}
                            >
                                <SlideFade in={isDropdownMenuOpen} offsetY={'-20px'}
                                    delay={isDropdownMenuOpen ? (index * 0.15) + 0.2 : 0}
                                >
                                    {navItem.name}
                                </SlideFade>
                            </Button>
                        ))}

                        {/* Download CV Button */}
                        <Box w={'100%'} py={1}>
                            <SlideFade in={isDropdownMenuOpen} offsetY={'-20px'}
                                delay={isDropdownMenuOpen ? (NavItems.length * 0.15) + 0.2 : 0}
                            >
                                <Button w={'100%'} borderRadius={'full'} borderColor={'#293241'} variant={'outline'}
                                    color={'#293241'} rightIcon={showDownloadIcon ? <DownloadButtonIcon /> : undefined}
                                    _hover={{ color: '#52A7E0', backgroundColor: '#293241', borderColor: '#52A7E0' }}
                                    onMouseEnter={() => { setShowDownloadIcon(true) }}
                                    onMouseLeave={() => { setShowDownloadIcon(false) }}
                                    onClick={() => { window.open(Resume_PDF, "_blank") }}
                                >
                                    Download CV
                                </Button>
                            </SlideFade>
                        </Box>
                    </VStack>
                </Collapse>
            </Box>
        </VStack >
    );
}

// The download icon shown on the download CV button
const DownloadButtonIcon = () => {
    const [showDownloadIcon, setShowDownloadIcon] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowDownloadIcon(true);
        }, 300);
    }, []);

    return (
        <Icon as={DownloadIcon} opacity={showDownloadIcon ? '100%' : 0} transition={'opacity .5s'} />
    )
}

export default Navbar;
