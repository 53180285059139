// Container for the timeline
// Allows the timeline to be filtered

import {
    Button,
    ButtonGroup,
    VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { TIMELINE_EVENTS } from '../timelineEvents';
import { Timeline } from "./";
import { TimelineItem } from "../../../../../types";

// The possible filter options
type FilterOption = 'all' | 'experience' | 'projects';

const TimelineContainer = () => {
    // The timeline events to display
    const [filteredTimelineEvents, setFilteredTimelineEvents] = useState<TimelineItem[]>([]);

    // The filter setting selected
    const [selectedFilter, setSelectedFilter] = useState<FilterOption>('all');

    // Sorts & filters timeline events on load
    //      and whenever filter option changes
    useEffect(() => {
        let filteredEvents = [...TIMELINE_EVENTS];

        // Checks if experience filter is selected
        if (selectedFilter === 'experience') {
            filteredEvents = filteredEvents.filter(
                event => event.type === 'Experience' || event.type === 'Life'
            );
        }

        // Checks if project filter is selected
        else if (selectedFilter === 'projects') {
            filteredEvents = filteredEvents.filter(
                event => event.type === 'Project'
            )
        }

        // Sorts events chronologically with most recent first
        filteredEvents.sort(
            (event1, event2) => (
                event1.startDate < event2.startDate ? 1 : -1
            )
        );

        setFilteredTimelineEvents(filteredEvents);
    }, [selectedFilter]);

    return (
        <VStack w={'100%'} spacing={6} mt={8}>
            {/* Timeline Filter */}
            <ButtonGroup isAttached variant={'outline'} w={'80%'} maxW={'400px'} justifySelf={'center'}>
                <FilterButton onClick={() => setSelectedFilter('experience')}
                    isSelected={selectedFilter === 'experience'}
                >
                    Experience
                </FilterButton>
                <FilterButton onClick={() => setSelectedFilter('all')}
                    isSelected={selectedFilter === 'all'}
                >
                    All
                </FilterButton>
                <FilterButton onClick={() => setSelectedFilter('projects')}
                    isSelected={selectedFilter === 'projects'}
                >
                    Projects
                </FilterButton>
            </ButtonGroup>

            <Timeline timelineEvents={filteredTimelineEvents} />
        </VStack>
    )
}

type FilterButtonProps = {
    onClick: () => void,
    isSelected: boolean,
    children: React.ReactNode
};
const FilterButton = ({
    onClick,
    isSelected,
    children
}: FilterButtonProps) => {
    return (
        <Button w={'33.33%'} onClick={onClick}
            color={isSelected ? "#293241" : 'white'}
            bgColor={isSelected ? "#52A7E0" : undefined} borderRadius={'full'}
            _hover={isSelected ? {} : { color: "#293241", bgColor: 'white' }}
            fontSize={{base: 14, 'sm': 18}}
        >
            {children}
        </Button>
    )
}

export default TimelineContainer;
