// Timeline event info modal for Cooper's Cupids Project

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import { GithubIconButton } from "../../../../../../../components";
import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const CoopersCupidsInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'TypeScript', size: 'md' },
            { name: 'React', size: 'md' }
        ]
    ];

    // The body text to display
    const bodyText = [
        `
            Cooper's Cupids is a dating webapp I built with a friend as a 
            term project for a Software Engineering course at The Cooper Union.
        `,

        <>
            <Bold>I designed and developed the frontend</Bold> with React, and my 
            partner built the backend and database with Java and SQL.
        </>,

        `
            The site is fully responsive and contains features expected of a 
            dating app, including: Sign up & Login; Profile creation / editing; 
            User feed with a like / dislike mechanic; User matching; User 
            messaging.
        `,

        <>
            As one of my first large projects with React, building Cooper's 
            Cupids was a crucial learning experience. Splitting the development 
            work between frontend and backend prompted <Bold>extensive discussions 
            on how the data would be structured and passed between our respective 
            halves</Bold>. This process not only refined my technical skills, but 
            also underscored the importance of <Bold>effective communication and 
            coordination</Bold> in ensuring a cohesive and functional end-product.
        </>,

        <>
            One aspect of this project that I found particularly interesting was the 
            messaging system we implemented, which allows users to message each other 
            seamlessly and instantaneously. Researching methods of implementing this 
            feature and solving issues as they arose provided <Bold>invaluable hands-on 
            problem-solving experience</Bold>.
        </>
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Cooper's Cupids"
                    subtitle="Dating Web-App"
                    dateString="Spring 2021"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
                <GithubIconButton buttonUrl="https://github.com/alorber/Coopers-Cupids" />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default CoopersCupidsInfoModal;
