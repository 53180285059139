// Displays many Skill Block components

import { HStack, VStack } from "@chakra-ui/react";
import React, { useRef } from "react";

import { Skill } from "../../../../../types";
import { SkillBlock } from "../../../../../components";
import { useInView } from "framer-motion";

// Displays the given skill blocks in three columns.
// The large blocks are split between the two outer columns,
//      and all the small blocks are displayed in the center column.

const SkillsLayout = ({
    skills,
    // The spacing for the center column of the layout
    middleColumnSpacing = 1
}: {
    skills: Skill[],
    middleColumnSpacing?: number
}) => {
    // Reference to the skill blocks display
    const ref = useRef(null);
    // Tracks if skill blocks display is scrolled into viewport
    const isInView = useInView(ref, { once: true });
    // List of fade-in delays for the skill blocks
    const fadeInDelays = Array.from({ 'length': skills.length }, (_, index) => (index * 0.1) + .1);
    // Shuffles the above list to randomly distribute fade-in delays
    fadeInDelays.sort(() => Math.random() - 0.5);

    const smallSkills = skills.filter(skill => skill.size === 'md');
    const largeSkills = skills.filter(skill => skill.size === 'lg');

    return (
        <HStack ref={ref}>
            <VStack spacing={2}>
                {largeSkills.slice(0, largeSkills.length / 2).map((skill, index) => (
                    <SkillBlock key={index} {...skill} isDisplayed={isInView} displayDelay={fadeInDelays.pop() ?? 0} />
                ))}
            </VStack>
            <VStack spacing={middleColumnSpacing}>
                {smallSkills.map((skill, index) => (
                    <SkillBlock key={index} {...skill} isDisplayed={isInView} displayDelay={fadeInDelays.pop() ?? 0} />
                ))}
            </VStack>
            <VStack spacing={2}>
                {largeSkills.slice(largeSkills.length / 2).map((skill, index) => (
                    <SkillBlock key={index} {...skill} isDisplayed={isInView} displayDelay={fadeInDelays.pop() ?? 0} />
                ))}
            </VStack>
        </HStack>
    )
};

export default SkillsLayout;
