// Base components for timeline event info modal
// Re-used for all timeline event info modals

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack
} from "@chakra-ui/react"

import React from "react"

type TimelineEventModalWrapperProps = {
    isOpen: boolean,
    onClose: () => void,
    modalHeader?: React.ReactNode,
    // The children are displayed in the modal body
    children: React.ReactNode
}
const TimelineEventModalWrapper = ({
    isOpen,
    onClose,
    modalHeader,
    children
}: TimelineEventModalWrapperProps) => {

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size={'3xl'}>
            <ModalOverlay />
            <ModalContent w={'80%'} maxH={'96%'}>
                {modalHeader && (
                    <ModalHeader>
                        <VStack alignItems={'center'} mt={2}>
                            {modalHeader}
                        </VStack>
                    </ModalHeader>
                )}

                <ModalCloseButton />

                <ModalBody p={0} mx={0}>
                    {children}
                </ModalBody>

                <ModalFooter />
            </ModalContent>
        </Modal>
    )
}

export default TimelineEventModalWrapper;
