// Displays the timeline events

import React from "react";
import { TimelineEvent } from "./";
import { TimelineItem } from '../../../../../types';
import { VStack } from "@chakra-ui/react";

type TimelineProps = {
    timelineEvents: TimelineItem[]
};

const Timeline = ({
    timelineEvents
}: TimelineProps) => {

    return (
        <VStack spacing={{ base: 10, 'md': 5 }} w={'100%'}>
            {timelineEvents.map((timelineEvent, index) => (
                // Event images / icons are alternated between 
                //    left side and right side. A line extends 
                //    from the event image / icon towards the center.
                <TimelineEvent key={index + '-' + timelineEvents.length} timelineEvent={timelineEvent}
                    eventSide={index % 2 === 0 ? "Left" : 'Right'} />
            ))}
        </VStack>
    )
}

export default Timeline;
