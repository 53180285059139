// The image displayed for projects on the timeline

import { Box, Image } from '@chakra-ui/react';

import React from "react";

type TimelineEventImageProps = {
    // Whether the timeline event is in the viewport
    isInView: boolean,
    // Which side of the screen this is being displayed on
    eventSide: 'Left' | 'Right',
    // The image to display
    imageSrc: string,
};
const TimelineEventImage = ({
    isInView,
    eventSide,
    imageSrc
}: TimelineEventImageProps) => {

    // The box first fades in filled completely with color A, then 
    // the image fades in over it, leaving a border of color A
    return (
        <Box alignSelf={'start'} justifySelf={'center'}
            marginLeft={eventSide === 'Left' ? 'auto' : undefined}
            zIndex={1} bgColor={'#52A7E0'} borderRadius={'2xl'}
            opacity={isInView ? '100%' : 0} transition={'opacity 1s'}
        >
            <Image minW={['50px', '200px', '400px', '500px', '700px']}
                width={'100%'} maxW={'900px'}
                border={'10px solid transparent'} borderRadius={'2xl'}
                borderColor={isInView ? '#52A7E0' : 'transparent'}
                borderWidth={{ base: '5px', 'sm': '10px' }}
                src={imageSrc} opacity={isInView ? '100%' : 0}
                transition={`
                    opacity 1s linear 1s, 
                    border-color .3s linear 2.7s
                `} />
        </Box>
    )
}

export default TimelineEventImage;
