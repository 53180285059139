// Icon button that links to a given url

import {
    IconButton,
    IconButtonProps,
    Link
} from "@chakra-ui/react";

import React from "react";

type IconLinkButtonProps = {
    href: string,
    ariaLabel: string,
    icon: React.JSX.Element,
    color?: IconButtonProps['color'],
    boxSize?: IconButtonProps['boxSize']
};
const IconLinkButton = ({
    href,
    ariaLabel,
    icon,
    color = "#293241",
    boxSize = '60px'
}: IconLinkButtonProps) => {

    return (
        <IconButton
            as={Link} isExternal
            href={href}
            aria-label={ariaLabel}
            boxSize={boxSize}
            border={'2px solid #293241'}
            borderRadius={'lg'}
            color={color}
            bgColor={'transparent'}
            // On hover, sets the icon color to white
            //   and the background to icon color.
            _hover={{
                color: 'white',
                bgColor: color,
                'svg *': {
                    fill: 'white'
                }
            }}
            icon={icon}
        />
    );
}

export default IconLinkButton;
