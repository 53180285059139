import * as React from "react"

import { FaMoon, FaSun } from "react-icons/fa"
import {
    IconButton,
    IconButtonProps,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react"

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">

const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
    const { toggleColorMode } = useColorMode()
    const text = useColorModeValue("dark", "light")
    const SwitchIcon = useColorModeValue(FaMoon, FaSun)

    return (
        <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={toggleColorMode}
            icon={<SwitchIcon />}
            aria-label={`Switch to ${text} mode`}
            {...props}
        />
    )
}

export default ColorModeSwitcher;
