// Ethereum Icon

import { Icon, IconProps } from "@chakra-ui/react";

import React from "react"

const EthereumIcon = (iconProps: IconProps) => {
    return (
        <Icon viewBox="0 0 327.5 533.3" {...iconProps}>
            <path fill="#8A92B2" d="M163.7,197.2V0L0,271.6L163.7,197.2z" />
            <path fill="#62688F" d="M163.7,368.4V197.2L0,271.6L163.7,368.4z M163.7,197.2l163.7,74.4L163.7,0V197.2z" />
            <path fill="#454A75" d="M163.7,197.2v171.2l163.7-96.8L163.7,197.2z" />
            <path fill="#8A92B2" d="M163.7,399.4L0,302.7l163.7,230.7V399.4z" />
            <path fill="#62688F" d="M327.5,302.7l-163.8,96.7v134L327.5,302.7z" />
        </Icon>
    );
}

export default EthereumIcon;
