// Timeline event info modal for KeyMe Internship

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const KeyMeInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'TypeScript', size: 'md' },
            { name: 'Vue', size: 'md' },
            { name: 'Python', size: 'md' },
            { name: 'Git', size: 'md' }
        ]
    ];

    // The body text to display
    const bodyText = [
        `
            My first internship, the summer between high-school and college, was as a 
            Frontend Engineering Intern at KeyMe.
        `,

        <>
            I collaborated closely with the frontend team and worked on variety of projects
            related to their touch-screen key-copying kiosks. I built an end-to-end <Bold>
            easter-egg coupon experience</Bold> to incentivize purchase and I <Bold>
            revamped the shopping cart navigation</Bold> by determining the underlying
            bug, investigating alternatives, and implementing a robust solution to increase
            product stability
        </>,

        `
            As my first internship, this was a huge learning experience that laid the 
            foundation for my career. I gained valuable hands-on experience in 
            collaborative programming, working seamlessly within a team environment, 
            version control and code reviews, communication, and the dynamics of real-
            world software development.
        `,

        `
            This was a pivotal stepping stone in my career, setting the stage for the 
            my continuous growth and learning in this field.
        `
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Frontend Engineering Intern"
                    subtitle="KeyMe"
                    dateString="Summer 2017"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default KeyMeInfoModal;
