// Timeline event info modal for Cooper Union

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    TimelineEventModalWrapper
} from "../../components";

import React from "react";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const CooperUnionInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    const bodyText = [
        <>
            I attended college at The Cooper Union in New York City,
            where I graduated with a B.E in <Bold>Electrical Engineering</Bold>
            , concentration in <Bold>Computer Engineering</Bold> and a minor
            in <Bold>Computer Science</Bold>.
        </>,

        <>
            I graduated <Bold>Summa Cum Laude</Bold> with Dean's List all
            semesters, and I received the Jesse Sherman Award for an excellent
            academic record & superior electrical engineering lab work. I was
            admitted into the <Bold>IEEE - Eta Kappa Nu</Bold> and
            the <Bold>Tau Beta Pi</Bold> honor societies.
        </>,

        <>
            My dual electrical / computer engineering focus allowed
            me to take many great classes related to both hardware
            and software.I also took various courses in Cooper
            Union's Master's Program.
        </>,

        <p style={{ textAlign: 'left' }}>
            Some of my courses include  {' '}
            <b>[Master's courses underlined]</b>: {' '}
            Data Structures & Algorithms I & II, {' '}
            <u>Artificial Intelligence</u>, {' '}
            Communication Networks, {' '}
            Operating Systems, {' '}
            <u>Deep Learning</u>, {' '}
            Software Engineering, {' '}
            <u>Compilers</u>, {' '}
            <u>Natural Language Processing</u>, {' '}
            <u>Databases</u>, {' '}
            <u>Theoretical Computer Science</u>
            .
        </p>,

        <>
            At Cooper Union I was heavily involved in <Bold>Hillel</Bold>,
            the Jewish organization on campus. I joined Hillel as
            the <Bold>Event Coordinator</Bold> from Fall 2018 to Fall 2019.
            From Fall 2019 to Fall 2020, I held the position
            of <Bold>Vice President</Bold>, and from Fall 2020 to Fall
            2021 I was <Bold>President</Bold> of Hillel.
        </>
    ]

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Cooper Union"
                    subtitle="B.E Electrical Eng. / Computer Eng."
                    dateString="2018 - 2022"
                />
            }
        >
            <VStack>
                <ModalBodyText bodyText={bodyText} />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default CooperUnionInfoModal;
