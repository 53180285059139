// List of events to display on the timeline

import {
    CooperNFTInfoModal,
    CooperUnionInfoModal,
    CoopersCupidsInfoModal,
    CsForSocialGoodInfoModal,
    FacebookInfoModal,
    KeyMeInfoModal,
    MixxInfoModal,
    RoboticsCrashCourseInfoModal,
    StealthStartupInfoModal
} from './timeline-event-info-modals/modals';
import {
    Cooper_Cupids_Home_Screen_Image,
    Cooper_NFT_Home_Screen_Image,
    Mixx_Home_Screen_Image
} from '../../../../assets/images';

import { TimelineItem } from "../../../../types";
import moment from 'moment';

// Timeline events are split into three types:
//      Project, Experience (job), and Life (School etc.)

export const TIMELINE_EVENTS: TimelineItem[] = [
    // ----------------------
    //          Life
    // ----------------------

    // Cooper Union
    {
        type: 'Life',
        title: 'Graduated Cooper Union',
        subtitle: 'B.E Computer Eng.',
        dateString: 'May 2022',
        startDate: moment('2022-04', 'YYYY-MM'),
        endDate: moment('2022-04', 'YYYY-MM'),
        iconType: 'School',
        infoModal: CooperUnionInfoModal
    },
    {
        type: 'Life',
        title: 'Started Cooper Union',
        subtitle: 'B.E Computer Eng.',
        dateString: 'September 2018',
        startDate: moment('2018-09', 'YYYY-MM'),
        endDate: moment('2018-09', 'YYYY-MM'),
        iconType: 'School',
        infoModal: CooperUnionInfoModal
    },

    // ----------------------
    //      Experiences
    // ----------------------

    // KeyMe - Frontend Engineering Intern
    {
        type: 'Experience',
        title: 'Frontend Engineering Intern',
        subtitle: 'KeyMe',
        dateString: 'Summer 2017',
        startDate: moment('2017-06', 'YYYY-MM'),
        endDate: moment('2017-08', 'YYYY-MM'),
        iconType: 'Work',
        infoModal: KeyMeInfoModal
    },

    // CU Summer STEM - CS for Social Good
    {
        type: 'Experience',
        title: 'Computer Science Teacher',
        subtitle: 'Cooper Union Summer STEM',
        dateString: 'Summer 2019',
        startDate: moment('2019-06', 'YYYY-MM'),
        endDate: moment('2019-08', 'YYYY-MM'),
        iconType: 'Work',
        infoModal: CsForSocialGoodInfoModal
    },

    // CU Summer STEM - Robotics Crash Course
    {
        type: 'Experience',
        title: 'Robotics Teacher',
        subtitle: 'Cooper Union Summer STEM',
        dateString: 'Summer 2020',
        startDate: moment('2020-06', 'YYYY-MM'),
        endDate: moment('2020-08', 'YYYY-MM'),
        iconType: 'Work',
        infoModal: RoboticsCrashCourseInfoModal
    },

    // Facebook - Frontend Engineering Intern
    {
        type: 'Experience',
        title: 'Frontend Engineering Intern',
        subtitle: 'Facebook',
        dateString: 'Summer 2021',
        startDate: moment('2021-05', 'YYYY-MM'),
        endDate: moment('2021-08', 'YYYY-MM'),
        iconType: 'Work',
        infoModal: FacebookInfoModal
    },

    // Stealth Startup - Founding Fullstack Engineer
    {
        type: 'Experience',
        title: 'Founding Full-Stack Engineer',
        subtitle: 'Stealth Startup',
        dateString: 'January - October 2023',
        startDate: moment('2023-01', 'YYYY-MM'),
        endDate: moment('2023-10', 'YYYY-MM'),
        iconType: 'Work',
        infoModal: StealthStartupInfoModal
    },

    // ----------------------
    //      Projects
    // ----------------------

    // Cooper's Cupids
    {
        type: 'Project',
        title: "Cooper's Cupids",
        subtitle: "Dating Web-App",
        dateString: "Spring 2021",
        startDate: moment('2021-03', 'YYYY-MM'),
        endDate: moment('2021-04', 'YYYY-MM'),
        imageSrc: Cooper_Cupids_Home_Screen_Image,
        infoModal: CoopersCupidsInfoModal
    },

    // Mixx
    {
        type: 'Project',
        title: 'Mixx',
        subtitle: "A Home-Bartender's Best Friend",
        dateString: "Fall 2021",
        startDate: moment('2021-11', 'YYYY-MM'),
        endDate: moment('2021-12', 'YYYY-MM'),
        imageSrc: Mixx_Home_Screen_Image,
        infoModal: MixxInfoModal
    },

    // CU NFT Marketplace
    {
        type: 'Project',
        title: 'Cooper Union NFT Marketplace',
        dateString: "Spring 2022",
        startDate: moment('2022-03', 'YYYY-MM'),
        endDate: moment('ß2022-04', 'YYYY-MM'),
        imageSrc: Cooper_NFT_Home_Screen_Image,
        infoModal: CooperNFTInfoModal
    }
];
