// The about section of the home page

import {
    Box,
    Button,
    Collapse,
    HStack,
    Heading,
    SlideFade,
    Stack,
    Text,
    VStack
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

import { ABOUT_ME_TEXTS } from './aboutMeTexts';
import { SKILLS } from './skills';
import SkillsLayout from './components/SkillsLayout';
import { useInView } from 'framer-motion';

const AboutMeHomeSection = () => {
    // Reference to the about me text
    const ref = useRef(null);
    // Tracks if about me text is scrolled into viewport
    const isInView = useInView(ref, { once: true });
    // Whether the rest of the about me text should be shown
    const [expandAboutMeText, setExpandAboutMeText] = useState(false);

    return (
        <VStack id='AboutMeHomeSection' w={'100%'} pb={20}>
            {/* About Me "Puzzle-piece" Header */}
            <Heading fontSize={[18, 24, 36]} textAlign={'center'} p={5} mt={-3}
                backgroundColor={'#293241'} color={'white'} borderRadius={'full'}>
                About Me
            </Heading>

            {/* About me text surrounded by skills */}
            {/* On extra-large screens, the two skill block groups are on the left & right of the text. */}
            {/* On large screens, both skill block groups are side-by-side on top of text. */}
            {/* On all smaller screens, the skill block groups are on top & bottom of text. */}
            <Stack w={'90%'} direction={{ 'base': 'column', '2xl': 'row' }}>
                {/* Layout of skill icon blocks */}
                <HStack spacing={6} alignSelf={'center'}>
                    {/* Always shows the left-side skill blocks group */}
                    <Box>
                        <SkillsLayout skills={SKILLS.slice(0, 8)} />
                    </Box>
                    {/* Just on XL screens, will show the right-side skill block group next to the left-side group. */}
                    {/* The surrounding stack also changes direction to a column to place them side-by-side. */}
                    <Box display={{ 'base': 'none', 'xl': 'block', '2xl': 'none' }}>
                        <SkillsLayout skills={SKILLS.slice(8)} />
                    </Box>
                </HStack>

                {/* About Me Text */}
                {/* Padding & Margins updates based on placement of skill blocks group. */}
                <VStack width={{ 'base': '90%', '2xl': '100%' }} alignSelf={{ base: 'center', '2xl': 'start' }}
                    mt={{ base: 0, '2xl': 5 }} spacing={2}
                >
                    {/* Only shows the first two paragraphs of the about me text */}
                    {ABOUT_ME_TEXTS.slice(0, 2).map((text, index) => (
                        <SlideFade in={isInView} offsetY={'30px'} delay={.2 * (index)} key={index}>
                            <Text fontWeight={index === 0 ? 'bold' : 'light'}
                                fontSize={[14, 16, 18]} alignSelf={'start'} w={'100%'} color={'#293241'}
                                px={{ 'base': 5, 'lg': 10, '2xl': 20 }} mt={{ 'base': 2, 'xl': 5, '2xl': 0 }}
                                mb={{ 'base': 2, 'xl': 0 }} textAlign={'justify'} ref={index === 0 ? ref : undefined}
                            >
                                {text}
                            </Text>
                        </SlideFade>
                    ))}

                    {/* The rest of the about me text is collapsible by a button */}
                    <Collapse in={expandAboutMeText} animateOpacity>
                        <VStack w={'100%'} spacing={2}>
                            {ABOUT_ME_TEXTS.slice(2).map((text, index) => (
                                <SlideFade in={isInView && expandAboutMeText} offsetY={'30px'} delay={1.2 * (index - 2)} key={index}>
                                    <Text fontWeight={index === ABOUT_ME_TEXTS.length - 3 ? 'bold' : 'light'}
                                        fontSize={[14, 16, 18]} alignSelf={'start'} w={'100%'} color={'#293241'}
                                        px={{ 'base': 5, 'lg': 10, '2xl': 20 }} mt={{ 'base': 2, 'xl': 5, '2xl': 0 }}
                                        mb={{ 'base': 2, 'xl': 0 }} textAlign={'justify'}
                                    >
                                        {text}
                                    </Text>
                                </SlideFade>
                            ))}
                        </VStack>
                    </Collapse>

                    {/* The button to expand / shrink about me text */}
                    <SlideFade in={isInView} offsetY={'30px'} delay={.6}>
                        <Button variant={'outline'} borderRadius={'lg'} borderColor={'#293241'} color={'#293241'}
                            _hover={{ color: '#52A7E0', backgroundColor: '#293241', borderColor: '#52A7E0' }}
                            onClick={() => { setExpandAboutMeText(!expandAboutMeText) }} mt={2} mb={4}
                            >
                            {expandAboutMeText ? 'Show Less' : 'Show More'}
                        </Button>
                    </SlideFade>
                </VStack>

                {/* Layout of skill icon blocks */}
                {/* Hides on xl screens, when this right-side skill blocks section is shown next to the left-side section above. */}
                <Box display={{ 'base': 'block', 'xl': 'none', '2xl': 'block' }} alignSelf={'center'}>
                    <SkillsLayout skills={SKILLS.slice(8)} />
                </Box>
            </Stack>
        </VStack>
    );
}

export default AboutMeHomeSection;
