// Home Page

import {
    AboutMeHomeSection,
    ContactMeHomeSection,
    IntroductionHomeSection,
    TimelineHomeSection
} from './sections';

import { Navbar } from '../../sections';
import React from 'react';
import { VStack } from '@chakra-ui/react';

const HomePage = () => {
    return (<>
        <Navbar />
        <VStack height={'100%'} spacing={0}>
            <IntroductionHomeSection />
            <AboutMeHomeSection />
            <TimelineHomeSection />
            <ContactMeHomeSection />
        </VStack>
    </>);
};

export default HomePage;
