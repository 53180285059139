// Timeline event info modal for Cooper NFT Project

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import { GithubIconButton } from "../../../../../../../components";
import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const CooperNFTInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'TypeScript', size: 'md' },
            { name: 'React', size: 'md' }
        ],
        [
            { name: 'Ethereum', size: 'md' },
            { name: 'Solidity', size: 'md' },
            { name: 'IPFS', size: 'md' }
        ]
    ];

    // The body text to display
    const bodyText = [
        <>
            As my Senior Project at The Cooper Union, <Bold>I designed and developed
            </Bold> a Cooper Union NFT marketplace. <Bold>I collaborated with faculty 
            and students</Bold> of the schools of Art and Architecture to determine core 
            features and functionality they would need, and <Bold>I iterated on these designs 
            through regular discussions</Bold> with these groups.
        </>,

        <>
            I built the marketplace on the Ethereum blockchain using the Solidity 
            language, the ERC1155 token standard, and the EIP2981 royalty standard. 
            <Bold> I designed Ethereum smart contracts</Bold> that control the minting 
            of NFTS and marketplace functionality. <Bold>I developed the frontend </Bold> 
            in React, using MetaMask to interact with users' Ethereum wallets and IPFS to 
            store users' NFTs.
        </>,

        <>
            The idea of a Cooper-run NFT marketplace had been occasionally discussed
            in the art and architecture schools as a means of allowing artist and 
            architects to begin exposing themselves to the industry throughout college. 
            The lack of a required production or show schedule, as is regular in museums 
            or fairs, would allow students to produce and sell their art / NFTs while also 
            fulfilling their personal responsibilities and coursework. Therefore, they could 
            build an early reputation in the market before graduation, earn some money to 
            support their endeavors post graduation, and help promote the Cooper Union brand. 
            While brainstorming ideas for my Senior Project, a teacher mentioned the prior 
            discussions concerning this marketplace and the interest in one being created.
            <Bold> I decided I would work with those involved on bringing this idea to life 
            </Bold> and getting the ball rolling on this endeavor.
        </>,

        `
            The purpose of this project was to design and develop an Ethereum-based NFT 
            marketplace based on the ERC-1155 token standard that would restrict minting to 
            Cooper Union students alone and be driven by the Cooper Union name-brand. This 
            proof-of-concept would showcase the possibilities of applying an NFT marketplace 
            to an academic institution and would initiate meaningful conversations within 
            Cooper Union on its future development.
        `,

        <>
            Building a Cooper Union NFT marketplace was an ambitious undertaking that offered 
            immense learning opportunities. <Bold>I delved deep into the realm of blockchain</Bold>
            , digital currency, and Ethereum, researching the intricacies of these topics in 
            which I had no prior experience. This immersive exploration not only broadened my 
            technical skill-set, but also <Bold>honed my ability to navigate and learn new and 
            evolving areas of technology</Bold>. Working closely with faculty and students 
            <Bold> heightened my ability to ascertain and address the nuanced needs of end-users
            </Bold>. Regular discussions with these stakeholders fostered an iterative design 
            process, refining my project management and communication skills. Engaging with 
            stakeholders and adapting the project to their requirements instilled a <Bold>user-centric
            development approach</Bold> and emphasized the importance of <Bold>effective 
            collaboration, communication, and adaptability</Bold> in successfully delivering a 
            product. This exposure to real-world challenges and iterative development cycles 
            significantly contributed to my growth as a well-rounded software engineer, equipping 
            me with the skills and mindset needed to tackle complex problems and foster collaborative 
            solutions.
        </>
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Cooper NFT Marketplace"
                    subtitle="An NFT Marketplace for CU Art School"
                    dateString="Fall 2021"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
                <GithubIconButton buttonUrl="https://github.com/alorber/Cooper-NFT" />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default CooperNFTInfoModal;
