// The Contact Me section of the home page

import {
    Box,
    HStack,
    Heading,
    IconProps,
    VStack,
    useBreakpointValue
} from '@chakra-ui/react';
import React, { useRef } from 'react';

import { AiFillLinkedin } from 'react-icons/ai';
import { FaGithub } from 'react-icons/fa';
import { GmailIcon } from '../../../../assets/icons';
import { IconBaseProps } from 'react-icons';
import { IconLinkButton } from '../../../../components';
import { useInView } from 'framer-motion';

const ContactMeHomeSection = () => {
    // Reference to the contact me buttons
    const ref = useRef(null);
    // Tracks if contact me buttons are scrolled into viewport
    const isInView = useInView(ref, { once: true });

    // The size of the icons in the buttons
    // Icon component doesn't allow responsive array, so need
    //      to do it here.
    const iconSize = useBreakpointValue({ base: '40px', 'md': '60px' }) ?? '40px';

    // Point of Contact buttons to display
    const pointsOfContacts: {
        // Name is used as the aria-label
        name: string,
        href: string,
        icon: React.FC<IconProps> | React.FC<IconBaseProps>,
        // The color that will be used for the icon
        // On hover, the icon will turn white and the background will turn this color
        // If not given, the default is dark blue
        color?: IconProps['color']
    }[] = [{
        // Email
        // ------
        name: 'Email',
        href: "mailto: andrewlorber5@gmail.com?subject=Contacting you through your website!",
        icon: GmailIcon,
        color: '#c71610'
    }, {
        // GitHub
        // --------
        name: 'GitHub',
        href: "https://www.github.com/alorber",
        icon: FaGithub
    }, {
        // LinkedIn
        // ---------
        name: 'LinkedIn',
        href: "https://www.linkedin.com/in/andrewlorber/",
        icon: AiFillLinkedin,
        color: '#0A66C2'
    }];

    return (
        <VStack id='ContactMeHomeSection' w={'100%'} pb={20}>
            {/* Contact Me "Puzzle-piece" Header */}
            <Heading fontSize={[18, 24, 36]} textAlign={'center'} p={5} mt={-3}
                backgroundColor={'#293241'} color={'white'} borderRadius={'full'}>
                Contact Me
            </Heading>

            <VStack w={'90%'} spacing={8} mt={4}>
                {/* Text above buttons */}
                <VStack spacing={4}>
                    <Heading fontSize={{ base: 16, 'sm': 20, 'md': 24 }} fontWeight={'normal'}
                        maxW={'700px'} textAlign={'center'}
                    >
                        Whether you want to discuss something specific or chat about a hobby,
                        I am always happy to talk.
                    </Heading>

                    <Heading fontSize={{ base: 14, 'sm': 18, 'md': 22 }} fontWeight={'medium'} textAlign={'center'}>
                        I look forward to hearing from you!
                    </Heading>
                </VStack>

                {/* Points of Contact Buttons */}
                <HStack ref={ref}>
                    {pointsOfContacts.map((pointOfContact, index) => (
                        <Box opacity={isInView ? '100%' : 0} transition={`opacity .5s linear ${.5 * index}s`} key={index}>
                            <IconLinkButton
                                href={pointOfContact.href}
                                ariaLabel={pointOfContact.name}
                                boxSize={{ base: '60px', md: '80px' }}
                                icon={<pointOfContact.icon boxSize={iconSize} size={iconSize} />}
                                color={pointOfContact.color}
                            />
                        </Box>
                    ))}
                </HStack>
            </VStack>
        </VStack>
    );
}

export default ContactMeHomeSection;
