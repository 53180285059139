// Display icons for a given list of skills

import { HStack } from "@chakra-ui/react";
import React from "react";
import { Skill } from '../../../../../../types';
import { SkillBlock } from '../../../../../../components';

type ModalSkillsLayoutProps = {
    // An array of arrays of skills
    // Each array is another row of skills to display
    skillRows: Skill[][]
}
const ModalSkillsLayout = ({
    skillRows
}: ModalSkillsLayoutProps) => {
    return (<>
        {skillRows.map((skillRow, rowIndex) => (
            <HStack flexWrap={'wrap'} justifyContent={'center'} key={rowIndex}>
                {skillRow.map((skill, skillIndex) => (
                    <SkillBlock name={skill.name} size={skill.size} key={skillIndex} />
                ))}
            </HStack>
        ))}
    </>)
}

export default ModalSkillsLayout;
