// Header Text for timeline event modal

import { Heading } from "@chakra-ui/react";
import React from "react";

type ModalHeaderTextProps = {
    title: string,
    subtitle: string,
    dateString: string
};
const ModalHeaderText = ({
    title,
    subtitle,
    dateString
}: ModalHeaderTextProps) => {

    return (<>
        <Heading fontSize={{ base: 18, 'sm': 24, 'md': 28 }} fontWeight={'medium'}>
            {title}
        </Heading>
        <Heading fontSize={{ base: 16, 'sm': 20, 'md': 24 }}>
            {subtitle}
        </Heading>
        <Heading fontSize={{ base: 14, 'sm': 16, 'md': 20 }}
            fontWeight={{ base: 'normal', 'md': 'light' }}
        >
            {dateString}
        </Heading>
    </>);
}

export default ModalHeaderText;
