// Solidity Icon

import { Icon, IconProps } from "@chakra-ui/react";

import React from "react"

const SolidityIcon = (iconProps: IconProps) => {
    return (
        <Icon viewBox="0 0 128 128" {...iconProps}>
            <path d="M84.466 0L63.887 36.578H22.756L43.321 0z" opacity=".45" />
            <path d="M63.887 36.576h41.145L84.468 0H43.323z" opacity=".6" />
            <path d="M43.323 73.138l20.565-36.562L43.323 0 22.759 36.576z" opacity=".8" />
            <path d="M43.521 128L64.1 91.424h41.145L84.666 128z" opacity=".45" />
            <path d="M64.1 91.426H22.955l20.564 36.575h41.145z" opacity=".6" />
            <path d="M84.666 54.864L64.102 91.426l20.564 36.575 20.579-36.575z" opacity=".8" />
        </Icon>
    );
}

export default SolidityIcon;