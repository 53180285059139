// The icon displayed for experience / life events on the timeline

import React, {
    useEffect,
    useState
} from "react";

import { FaGraduationCap } from 'react-icons/fa'
import { Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdWork } from 'react-icons/md';
import { TimelineIconType } from '../../../../../types';

type TimelineEventIconProps = {
    // Whether the timeline event is in the viewport
    isInView: boolean,
    // Which side of the screen this is being displayed on
    eventSide: 'Left' | 'Right',
    iconType: TimelineIconType
}
const TimelineEventIcon = ({
    isInView,
    eventSide,
    iconType
}: TimelineEventIconProps) => {
    // Maps the icon type to the icon components
    const iconByType: {
        [iconType in TimelineIconType]: IconType
    } = {
        'School': FaGraduationCap,
        'Work': MdWork
    }

    // State variable to trigger background animation
    const [showBackground, setShowBackground] = useState(false);

    // Timer to trigger background animation
    useEffect(() => {
        if (isInView) {
            setTimeout(() => {
                setShowBackground(true);

                setTimeout(() => {
                    setShowBackground(false)
                }, 1000);
            }, 1000);
        }
    }, [isInView])

    // The icon fades in with color A, then fades to transparent as the
    // background fades to color A. Then the background fades to transparent
    // as the icon and border fade to color A.

    return (
        <Icon as={iconByType[iconType]} p={5}
            boxSize={['100px', '150px', '200px', '250px', '300px']}
            alignSelf={'start'} justifySelf={'center'} zIndex={1}
            marginLeft={eventSide === 'Left' ? 'auto' : undefined}
            border={'10px solid transparent'} borderRadius={'full'}
            borderColor={isInView ? '#52A7E0' : 'transparent'}
            borderWidth={{ base: '5px', 'sm': '10px' }}
            opacity={isInView ? '100%' : 0}
            color={showBackground ? '#293241' : '#52A7E0'}
            bgColor={showBackground ? '#52A7E0' : '#293241'}
            transition={`
                opacity .2s,
                color .2s,
                background-color .2s, 
                border-color 0s linear 1.2s
            `}
        />
    )
}

export default TimelineEventIcon;
