// Skill Block Component
// Stylized box that displays icon and name
// Used to display skills

import {
    Heading,
    Icon,
    VStack,
    useMediaQuery
} from "@chakra-ui/react";

import { DEV_ICONS } from "../../assets/icons";
import React from "react";
import { Skill } from '../../types';

type SkillBlockProps = {
    isDisplayed?: boolean,
    displayDelay?: number
} & Skill;

// Skill Blocks can be sized either large or small
const SkillBlock = ({
    name,
    size,
    isDisplayed = true,
    displayDelay = 0
}: SkillBlockProps) => {
    // Determines if screen width is <= 340px.
    // Below this value and the skill blocks push 
    //    off the edge of the screen.
    const [isSmallScreen] = useMediaQuery('(max-width: 340px)');

    // Maps the skill box sizes to their styling
    const sizedStyles: {
        [size in Skill['size']]: {
            boxWidth: (string | null)[],
            boxHeight: (string | null)[],
            fontSize: (number | null)[]
        }
    } = {
        // Adds an extra breakpoint as 340px
        's': {
            boxWidth: ['70px', null, '90px'],
            boxHeight: ['40px', null, '50px'],
            fontSize: [12, null, 14]
        },
        'md': {
            boxWidth: isSmallScreen ? ['70px'] : ['80px', null, '110px'],
            boxHeight: ['50px', null, '70px'],
            fontSize: [13, null, 16]
        },
        'lg': {
            boxWidth: isSmallScreen ? ['100px'] : ['115px', null, '130px'],
            boxHeight: ['70px', null, '90px'],
            fontSize: [16, null, 20]
        }
    };

    const skillStyles = sizedStyles[size];



    return (
        <VStack px={1} py={1}
            alignItems={'center'} justifyContent="center"
            border={'2px solid #52A7E0'} borderRadius={'lg'}
            spacing={0} background="transparent"
            w={skillStyles.boxWidth} h={skillStyles.boxHeight}
            opacity={isDisplayed ? 1 : 0} transition={`opacity 1s linear ${displayDelay}s`}
        >
            <Icon as={DEV_ICONS[name].icon} boxSize={'100%'} h={'70%'} />

            <Heading fontSize={skillStyles.fontSize} fontWeight={'light'}
                textAlign={'center'} mx={2} h='fit-content'
            >
                {DEV_ICONS[name].name}
            </Heading>
        </VStack>
    );
};

export default SkillBlock;
