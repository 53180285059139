// Timeline event info modal for Facebook - Frontend Engineering Internship

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const FacebookInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'TypeScript', size: 'md' },
            { name: 'React', size: 'md' },
            { name: 'GraphQL', size: 'md' }
        ]
    ];

    // The body text to display
    const bodyText = [
        `
            I spent the summer of 2021 as a Frontend Engineering Intern at Facebook (Meta).
        `,

        <>
            Over the course of my internship, <Bold>I worked on a new internal tooling
            experience</Bold> that brought all internal developer tools under one roof
            with a uniform UI and significantly easier onboarding experience.
        </>,

        <>
            My project was particular exciting, because I was working with a wide range of internal
            tools and the teams that built them. While migrating each tool to the new system I was
            developing, I had the opportunity to <Bold>collaborate with and learn from a wide variety
            of teams, engineers, and designers.</Bold>
        </>,

        <>
            Additionally, while working on my project, I noticed there was an area of overlap with 
            another intern's project. <Bold>I had the idea to collaborate closely on the overlapping 
            parts</Bold> in order to reduce extraneous work. I approached the other intern about the 
            idea and once he was on-board, I discussed the prospect with our managers, who thought it 
            was a great idea. This allowed us to focus on building better React components that could 
            be used by us both and others.
        </>,

        <>
            Since the user-base of my project was internal Facebook developers, I was able to <Bold> 
            demo my work to key users</Bold> in order to <Bold>incorporate their feedback</Bold> into 
            my final product.
        </>,

        <>
            In addition to the work I did on my project, <Bold>I added logging</Bold> to most components 
            / interactions on the new experience, and <Bold>I created a dashboard</Bold> to view countless 
            stats about the product's usage; this allowed the team continuing my work to make educated 
            decisions on what to focus their time on.
        </>,

        `
            This internship was a great learning experience. I gained many new technical skills, 
            including industry-level React and GraphQL, and I learned a lot about working on an 
            professional software team and collaborating closely with many engineers and product 
            designers.
        `
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Frontend Engineering Intern"
                    subtitle="Facebook"
                    dateString="Summer 2021"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default FacebookInfoModal;
