// Timeline event info modal for CU Summer STEM - CS for Social Good

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const CsForSocialGoodInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'React', size: 'md' },
            { name: 'Firebase', size: 'md' }
        ],
        [
            { name: 'HTML', size: 's' },
            { name: 'CSS', size: 's' },
            { name: 'Git', size: 's' }
        ]
    ];

    // The body text to display
    const bodyText = [
        `
            I spent the summer of 2019 at The Cooper Union Summer 
            STEM program co-teaching their Computer Science for Social 
            Good course.
        `,

        <>
            I, along with three others, developed a <Bold>six week
            curriculum</Bold> to teach <Bold>24 high school students
            </Bold> with no previous programming experience web application 
            development. Throughout the program, I <Bold>created presentations 
            and taught classes</Bold> on a range of topics, including JavaScript, 
            React, Firebase, HTML, & CSS.
        </>,

        `
            Class time was spent teaching or assisting the six groups of 
            students in designing and creating their web applications that supported 
            one of the United Nation's Sustainable Development Goals.
        `,

        `
            In order to teach something, one has to truly understand it. This 
            experience allowed me to delve deeper into these subjects, master 
            the fundamentals, learn from the students' interesting questions, 
            and assist a new group of high-schoolers in building an interest 
            in this amazing field.
        `
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Computer Science Teacher"
                    subtitle="Cooper Union Summer STEM"
                    dateString="Summer 2020"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default CsForSocialGoodInfoModal;
