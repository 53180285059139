// A dictionary that maps icon names to their components
// Saves each file from having to import all the icons separately

import {
    AWSIcon,
    ArduinoIcon,
    CSS3Icon,
    ChakraUIIcon,
    CppIcon,
    DockerIcon,
    EthereumIcon,
    ExpressIcon,
    FirebaseIcon,
    FlaskIcon,
    GitIcon,
    GmailIcon,
    GraphQLIcon,
    HTML5Icon,
    IPFSIcon,
    JavaScriptIcon,
    MongoDBIcon,
    NodeJSIcon,
    PostgreSQLIcon,
    PostmanIcon,
    PythonIcon,
    ReactIcon,
    SolidityIcon,
    TypeScriptIcon,
    VueIcon
} from './';

const DEV_ICONS = {
    'Arduino': {
        name: 'Arduino',
        icon: ArduinoIcon
    },
    'AWS': {
        name: 'AWS',
        icon: AWSIcon
    },
    'C++': {
        name: 'C++',
        icon: CppIcon
    },
    'Chakra': {
        name: 'ChakraUI',
        icon: ChakraUIIcon
    },
    'CSS': {
        name: 'CSS',
        icon: CSS3Icon
    },
    'Docker': {
        name: 'Docker',
        icon: DockerIcon
    },
    'Ethereum': {
        name: 'Ethereum',
        icon: EthereumIcon
    },
    'Express': {
        name: 'Express',
        icon: ExpressIcon
    },
    'Firebase': {
        name: 'Firebase',
        icon: FirebaseIcon
    },
    'Flask': {
        name: 'Flask',
        icon: FlaskIcon
    },
    'Git': {
        name: 'Git',
        icon: GitIcon
    },
    'Gmail': {
        name: 'Gmail',
        icon: GmailIcon
    },
    'GraphQL': {
        name: 'GraphQL',
        icon: GraphQLIcon
    },
    'HTML': {
        name: 'HTML',
        icon: HTML5Icon
    },
    'IPFS': {
        name: 'IPFS',
        icon: IPFSIcon
    },
    'JavaScript': {
        name: 'JavaScript',
        icon: JavaScriptIcon
    },
    'MongoDB': {
        name: 'MongoDB',
        icon: MongoDBIcon
    },
    'Node': {
        name: 'Node.JS',
        icon: NodeJSIcon
    },
    'PostgreSQL': {
        name: 'PostgreSQL',
        icon: PostgreSQLIcon
    },
    'Postman': {
        name: 'Postman',
        icon: PostmanIcon
    },
    'Python': {
        name: 'Python',
        icon: PythonIcon
    },
    'React': {
        name: 'ReactJS',
        icon: ReactIcon
    },
    'Solidity': {
        name: 'Solidity',
        icon: SolidityIcon
    },
    'TypeScript': {
        name: 'TypeScript',
        icon: TypeScriptIcon
    },
    'Vue': {
        name: 'VueJS',
        icon: VueIcon
    }
} as const;

export default DEV_ICONS;
