// Github icon button that will open the supplied url

import {
    Heading,
    VStack
} from "@chakra-ui/react";

import { FaGithub } from "react-icons/fa";
import { IconLinkButton } from "../";
import React from "react";

type GithubIconButtonProps = {
    buttonUrl: string
};

const GithubIconButton = ({
    buttonUrl
}: GithubIconButtonProps) => {

    return (
        <VStack>
            <Heading fontSize={[13, null, 16]}
                textAlign={'center'} mx={2} h='fit-content'
                textColor={'#293241'}
            >
                View more images and info on GitHub
            </Heading>
            <IconLinkButton
                href={buttonUrl}
                ariaLabel={"View on GitHub"}
                icon={<FaGithub size={'40px'} />}
            />
        </VStack>
    )
}

export default GithubIconButton;
