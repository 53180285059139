// The scrollable body text of the modal

import { Text, VStack } from "@chakra-ui/react";

import React from "react";

// Either an array of paragraphs can be supplied,
//      or a custom child that will be displayed
//      in the scrollable text box.
type ModalBodyTextProps = {
    // Each array element will be displayed
    //    on a separate line.
    bodyText: (string | JSX.Element)[]
    customChildren?: false,
    children?: undefined
} | {
    bodyText?: undefined,
    // If set to true, a child component(s)
    //      must be supplied
    customChildren: true,
    children: React.ReactNode,
}
const ModalBodyText = ({
    bodyText = [],
    customChildren = false,
    children
}: ModalBodyTextProps) => {

    return (
        <VStack w={'100%'} px={8} py={4} mt={4} spacing={4}
            bgColor={'#293241'} textColor={'white'}
            textAlign={{ base: 'left', 'md': 'justify' }}
            maxH={'40vh'} overflow={'auto'}
        >
            {customChildren ? (
                children
            ) : (
                bodyText.map((text, index) => (
                    <Text w={'100%'} fontSize={{ base: 13, 'sm': 14, 'md': 16 }}
                        key={index}
                    >
                        {text}
                    </Text>
                ))
            )}
        </VStack>
    )
}

export default ModalBodyText;
