// Text to display in about me section
// Each element in array is displayed separately in a stack

export const ABOUT_ME_TEXTS: string[] = [
    `I am a Full-Stack Developer with a genuine love for learning on a perpetual journey of 
    curiosity and creation. `,

    `My programming journey began during highschool, and since then, I've been on a self-taught 
    exploration of the tech universe. I love seeing what technology can create and accomplish, 
    and I am fascinated by the creative challenges that this field offers.`,

    `I graduated Summa Cum Laude from The Cooper Union with a B.E in Computer Engineering. I gained a 
    unique perspective by delving into the world of Electrical Engineering alongside Computer Science, 
    giving me a breadth of knowledge that I love to wield. During my time in college, I also had 
    the opportunity to spend two rewarding summers teaching computer science courses to high 
    school students, sharing my enthusiasm for this dynamic field. This experience not only honed my 
    technical skills, but also amplified my ability to communicate complex concepts with patience and clarity.`,

    `I'm always up for a challenge, whether it's diving into a programming project with a new language 
    or just tackling today's NY Times crossword puzzle. Beyond coding, you'll often find me engrossed 
    in a good book, planning my next move in a board game, immersing myself in a good video game, or
    acquiring random facts from the internet. I'm also a history enthusiast and love visiting museums 
    and historical sites. Mixology is another hobby I'm passionate about; just like in engineering, 
    I enjoy combining different elements to craft something special that's greater than the sum of its 
    parts.`,

    `With my love for problem-solving and a diverse set of interests, I'm all about exploring, learning, 
    teaching, and creating something awesome.`
];
