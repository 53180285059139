// Component to be used in a Text component
// Acts as a span tag, making the contained text bold and colored

import React from "react";

const BoldColoredText = ({
    children
}: { children: React.ReactNode }) => {

    return (
        <span style={{ fontWeight: 'Bold', color: '#52A7E0' }}>
            {children}
        </span>
    );
}

export default BoldColoredText;
