// List of skills to display on the about me section

import { Skill } from '../../../../types';

export const SKILLS: Skill[] = [
    { name: 'JavaScript', size: 'lg' },
    { name: 'TypeScript', size: 'lg' },
    { name: 'Node', size: 'lg' },
    { name: 'React', size: 'lg' },
    { name: 'Express', size: 'md' },
    { name: 'Chakra', size: 'md' },
    { name: 'HTML', size: 'md' },
    { name: 'CSS', size: 'md' },
    // ---- Above line is left side of screen, below is right
    { name: 'Python', size: 'lg' },
    { name: 'PostgreSQL', size: 'lg' },
    { name: 'GraphQL', size: 'lg' },
    { name: 'Git', size: 'lg' },
    { name: 'Flask', size: 'md' },
    { name: "Solidity", size: 'md' },
    { name: 'Docker', size: 'md' },
    { name: 'Postman', size: 'md' },
    // { name: 'AWS', size: 'md', icon: AWSIcon }
];

// 9 Medium
// 8 Large
