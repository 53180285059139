// Introduction section for the home page
// Top section with name and intro

import {
    Heading,
    Icon,
    VStack,
    useDimensions
} from "@chakra-ui/react";
import React, {
    useEffect,
    useRef,
    useState
} from "react";

import { FaChevronDown } from "react-icons/fa";
import { RotatingTextFade } from '../../../../components';

const IntroductionHomeSection = () => {
    // Chakra UI hooks to track the width of the name text
    const nameHeadingRef: React.MutableRefObject<any> = useRef();
    const nameHeadingDimensions = useDimensions(nameHeadingRef, true);
    const nameHeadingWidth = nameHeadingDimensions?.borderBox.width;

    // Sentences to rotate through and display on page
    const introTexts = [
        "I'm a Passionate Fullstack Developer Pushing Boundaries with Code.",
        "I Love Designing and Developing Exceptional Web Experiences.",
        "I Love Solving Complex Problems With Creative Solutions.",
        // "I Love Exploring Boundless Possibilities with Code.",
        "Web Developer | Creative Problem Solver | Tech Lover"
    ];

    // State variable for down arrows
    const [showArrows, setShowArrows] = useState(false);

    // Ripples the down arrows every second
    useEffect(() => {
        const interval = setInterval(() => {
            setShowArrows(!showArrows)
        }, 1000);

        return () => clearInterval(interval);
    });

    return (
        <VStack h={'100vh'} w={'100%'} backgroundColor={'#293241'} alignItems={'center'}>
            <VStack h={'90%'} justify="center" spacing={8}>
                <VStack spacing={2} alignSelf={'center'}>
                    <Heading fontWeight={'light'} color={'white'} fontSize={[21, 26, 31]} alignSelf={'start'}>
                        Hello, my name is
                    </Heading>

                    <Heading fontSize={[40, 45, 58, 64, 91]} color={'#52A7E0'} ref={nameHeadingRef}>
                        Andrew Lorber.
                    </Heading>
                </VStack>

                {/* Rotates through the text above using fade animations */}
                <Heading fontWeight={'light'} color={'white'} fontSize={[16, 18, 21]}
                    maxWidth={nameHeadingWidth} alignSelf={'start'}>
                    <RotatingTextFade textList={introTexts} />
                </Heading>
            </VStack>

            {/* 3 Down Arrows that will ripple to tell user to scroll  */}
            {/* Disappears once the user scrolls past them */}
            <VStack flex={'end'} spacing={0} pb={4} opacity={window.scrollY <= 150 ? 1 : 0} transition={'opacity 1s'}>
                {[...Array(3)].map((_, index) => (
                    <Icon as={FaChevronDown} key={index} color={'#52A7E0'} fontSize={36}
                        opacity={showArrows ? 1 : 0} transition={`opacity .5s linear ${0.2 * index}s`} />
                ))}
            </VStack>
        </VStack >
    );
}

export default IntroductionHomeSection;
