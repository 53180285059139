// Displays an event on the timeline

// Event images / icons are alternated between 
//    left side and right side. A line extends 
//    from the event image / icon towards the center.

import {
    Box,
    Button,
    HStack,
    Heading,
    SlideFade,
    VStack,
    useMediaQuery
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
    TimelineEventIcon,
    TimelineEventImage
} from './';

import { TimelineItem } from "../../../../../types";
import { useInView } from "framer-motion";
import { useRef } from "react";

type TimelineEventProps = {
    // The timeline event being shown
    timelineEvent: TimelineItem
    // Which side of the screen this is being displayed on
    eventSide: 'Left' | 'Right',
};

const TimelineEvent = ({
    timelineEvent,
    eventSide
}: TimelineEventProps) => {
    // Tracks if event is scrolled into viewport
    // Used for fade-in
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 'all' });

    // Tracks if event has already been faded in
    // Don't want info text to re-fade in when screen size becomes 
    //   small, if the text has already been seen. Since a different 
    //   component is displayed when the text is "moved", it will 
    //   render with the fade-in animation with a 3 second delay.
    const [infoTextAlreadySeen, setInfoTextAlreadySeen] = useState(false);

    // To keep the above state variable from becoming true and removing the 
    //    delay on the initial fade-in, there is a short delay before the
    //    variable is updated.
    useEffect(() => {
        if (isInView) {
            setTimeout(() => {
                setInfoTextAlreadySeen(true);
            }, 4000);
        }
    }, [isInView]);

    // Determines how to align event & text
    // Events are alternated between left side and right side
    const eventAlignment = eventSide === 'Left' ? 'start' : 'end';
    // Info text is aligned towards the center of the screen, so
    //   that it is at the end of the line extending towards the center.
    //   It will always be opposite event alignment.
    const textAlignment = eventSide === 'Left' ? 'end' : 'start';

    // Determines which image / icon to display based on the event type
    const eventPicture = timelineEvent.type === 'Project' ? (
        <TimelineEventImage isInView={isInView} eventSide={eventSide}
            imageSrc={timelineEvent.imageSrc} />
    ) : (
        <TimelineEventIcon isInView={isInView} eventSide={eventSide}
            iconType={timelineEvent.iconType} />
    );

    // Tracks info modal open / close
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Determines if screen width is <= 400px
    // Below this amount, and the Learn More button begins
    //      to overlap with the project image and styling needs 
    //      to be updated.
    const [isSmallScreen] = useMediaQuery('(max-width: 400px)');

    return (
        <VStack w={'100%'}>
            {/* 
                HStack width determines how far from the screen-edge the 
                    event will stretch. Ideally, event lines should all 
                    end / meet at the center of the screen, if it can fit. 
                On smaller screens, where more room is needed for info 
                    text, the allotted width is larger. 
            */}
            <HStack w={timelineEvent.type === 'Project' ? (
                // Since projects display an image instead of an icon,
                //   more room is needed to fit everything.
                { base: '90%', 'sm': '80%', 'md': '73%', 'lg': '66%' }
            ) : (
                { base: '80%', 'sm': '68%', 'md': '58%', 'lg': '52%' }
            )}
                alignSelf={eventAlignment} spacing={0}
                mr={eventSide === 'Right' ? 5 : 0} ml={eventSide === 'Left' ? 5 : 0}
            >
                {/* Event Picture - For Left-Side Event */}
                {eventSide === 'Left' && eventPicture}

                {/* Event Info */}
                <VStack spacing={1}
                    // Margin-Top ensures the line emerges from the center of the image.
                    mt={
                        // Since projects display an image instead of an icon,
                        //   a larger margin is required to account for the 
                        //   greater height of the image.
                        timelineEvent.type === 'Project' ? (
                            // Since the project info text is moved on smaller screens,
                            //    the margin needs to be adjusted
                            isSmallScreen ? (
                                -1
                            ) : (
                                { base: '50px', 'md': '65px' }
                            )
                        ) : (
                            { base: 5, 'sm': 35, 'md': 50 }
                        )}
                    // Determines width of line that extend from image / icon 
                    //   towards the center. This line shares the above HStack 
                    //   width with the event image / icon. As this line becomes
                    //   wider, the image / icon becomes smaller. On smaller screens,
                    //   line is given larger percentage of width to fit all the text.
                    w={{ base: '100vw', 'md': '70vw', 'lg': '60vw', 'xl': '25vw' }}
                    alignItems={textAlignment} ref={ref}
                    // Margin-Left/Right hides the gap between the line and the image border
                    ml={eventSide === 'Left' ? -1 : undefined}
                    mr={eventSide === 'Right' ? -1 : undefined}
                >
                    {/* Event Date */}
                    <SlideFade in={isInView} offsetY={-20} delay={3}>
                        <Heading color={'white'} textAlign={textAlignment}
                            fontSize={[14, 16, 18, 22, 24]}
                            // Padding-Left/Right keeps text from overlapping with icon
                            pl={(eventSide === 'Left') ? 2 : undefined}
                            pr={(eventSide === 'Right') ? 2 : undefined}
                        >
                            {timelineEvent.dateString}
                        </Heading>
                    </SlideFade>

                    {/* Line extending from image towards center */}
                    {/* The width transition makes the line 'grow' from the image
                    towards the center of the screen */}
                    <Box h={{ base: 2, 'sm': 3 }} w={isInView ? '100%' : 0}
                        bg="#52A7E0" zIndex={0}
                        // Only the end that shows should have the border radius
                        borderRightRadius={eventSide === 'Left' ? "full" : undefined}
                        borderLeftRadius={eventSide === 'Right' ? 'full' : undefined}
                        transition={'width .75s linear 2s'} alignSelf={eventAlignment} />

                    {/* If there is room, the event info text / button will be displayed
                    directly below the line, next to the image */}
                    {(!isSmallScreen || timelineEvent.type !== 'Project') && (
                        <TimelineEventInfo isInView={isInView} infoTextAlreadySeen={infoTextAlreadySeen}
                            eventTitle={timelineEvent.title} eventSubtitle={timelineEvent.subtitle}
                            setIsModalOpen={setIsModalOpen} isSmallScreen={isSmallScreen}
                            isProject={timelineEvent.type === 'Project'} eventSide={eventSide} />
                    )}
                </VStack>

                {/* Event Picture - For Right-Side Event */}
                {eventSide === 'Right' && eventPicture}

                {/* Info Modal */}
                <timelineEvent.infoModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} />
            </HStack>

            {/* If there is not enough room, the event info text / button will be displayed 
            below the image */}
            {isSmallScreen && timelineEvent.type === 'Project' && (
                <TimelineEventInfo isInView={isInView} infoTextAlreadySeen={infoTextAlreadySeen}
                    eventTitle={timelineEvent.title} eventSubtitle={timelineEvent.subtitle}
                    setIsModalOpen={setIsModalOpen} isSmallScreen={isSmallScreen}
                    isProject={timelineEvent.type === 'Project'} eventSide={eventSide} />
            )}
        </VStack>
    )
}

// The event title, subtitle, and learn more button.
// Separate, since placement is dependent on screen size.
type TimelineEventInfoProps = {
    // Whether the event is in view
    // Triggers the fade-in animation
    isInView: boolean
    eventSide: 'Left' | 'Right',
    eventTitle: string,
    eventSubtitle?: string,
    setIsModalOpen: (val: boolean) => void,
    // Whether the screen width is small enough to 
    //   affect styling.
    isSmallScreen: boolean,
    // Whether this event is a project
    isProject: boolean,
    // Whether this text has already been faded in on a 
    //    different screen size and doesn't need to be 
    //    faded in again on screen-size change.
    infoTextAlreadySeen: boolean
};
const TimelineEventInfo = ({
    isInView,
    eventSide,
    eventTitle,
    eventSubtitle,
    setIsModalOpen,
    isSmallScreen,
    isProject,
    infoTextAlreadySeen
}: TimelineEventInfoProps) => {
    // Responsive Font sizes for event text
    // Since event text is moved to separate line on 
    //   small screens, the font can be larger.
    const titlesFontSize = isSmallScreen ? (
        14
    ) : { base: 12, 'sm': 14, 'md': 16, 'lg': 18 };

    // On small screens, project info text is moved to separate line,
    //      which affects styling.
    const isTextOnOwnLine = isSmallScreen && isProject;

    // Determines how to align event text
    // Info text is aligned towards the center of the screen, so
    //   that it is at the end of the line extending towards the center.
    //   It will always be opposite event alignment.
    // On small screens, project info text is moved to separate line, so 
    //   it should be centered.
    const textAlignment = isTextOnOwnLine ? (
        'center'
    ) : (
        eventSide === 'Left' ? 'end' : 'start'
    );

    return (<>
        {/* Title & Subtitle */}
        <SlideFade in={isInView} offsetY={infoTextAlreadySeen ? 0 : 20}
            delay={infoTextAlreadySeen ? 0 : 3}
        >
            <VStack spacing={0} alignItems={textAlignment}
                // Padding-Left/Right keeps text from overlapping with icon
                // Don't need padding when text is on own line
                pl={(!isTextOnOwnLine && eventSide === 'Left') ? 2 : undefined}
                pr={(!isTextOnOwnLine && eventSide === 'Right') ? 2 : undefined}
            >
                <Heading fontSize={titlesFontSize} color={'white'}
                    mb={1} fontWeight={'light'} textAlign={textAlignment}>
                    {eventTitle}
                </Heading>

                {eventSubtitle && (
                    <Heading fontSize={titlesFontSize} color={'white'}
                        mb={1} fontWeight={'normal'} textAlign={textAlignment}>
                        {eventSubtitle}
                    </Heading>
                )}
            </VStack>
        </SlideFade>

        {/* Learn More Button */}
        <SlideFade in={isInView} offsetY={infoTextAlreadySeen ? 0 : 20}
            delay={infoTextAlreadySeen ? 0 : 3.5}
        >
            <Button variant={'outline'}
                color={'white'} borderRadius={'2xl'}
                onClick={() => { setIsModalOpen(true) }}
                _hover={{ color: "#293241", bgColor: 'white' }}
                fontSize={{ base: 12, 'sm': 14, 'md': 16 }}
            >
                Learn More
            </Button>
        </SlideFade>
    </>)
}


export default TimelineEvent;
