// Timeline section for home page
// Displays experience and projects

import { Heading, VStack } from "@chakra-ui/react";

import React from "react";
import { TimelineContainer } from './components';

const TimelineHomeSection = () => {

    return (
        <VStack id="TimelineHomeSection" w={'100%'}
            backgroundColor={"#293241"} pb={20}>
            {/* Timeline "Puzzle-piece" Header */}
            <Heading fontSize={[18, 24, 36]} textAlign={'center'} p={5} mt={-3}
                backgroundColor={'white'} color={"#293241"} borderRadius={'full'}>
                Experience & Projects
            </Heading>

            {/* Timeline to display experience & projects */}
            <TimelineContainer />
        </VStack>
    )
}

export default TimelineHomeSection;
