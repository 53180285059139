// Cycles through an array of text, 
//  fading the old text out and new text in indefinitely

import React, { useEffect, useState } from 'react';

type RotatingTextFadeProps = {
    textList: string[],
    // The number of milliseconds between words shown
    wordFrequency?: number
    // The number of milliseconds a text is shown for
    textDuration?: number
}
const RotatingTextFade = ({
    textList,
    wordFrequency = 100,
    textDuration = 7000
}: RotatingTextFadeProps) => {
    // State variables to track current text and word
    const [currentSentence, setCurrentSentence] = useState(0);
    const [currentWord, setCurrentWord] = useState(0);
    const [textOpacity, setTextOpacity] = useState(1);

    // Every {{textDuration}} milliseconds, the fade-out animation begins
    useEffect(() => {
        const interval = setInterval(() => {
            // Sets opacity to zero
            // Begins fade-out animation
            setTextOpacity(0);
            setCurrentWord(0);

            // Gives time for fade-out animation

            // After 3 seconds, begins fade-in animation of next word
            setTimeout(() => {
                setCurrentSentence((prevIndex) => (prevIndex + 1) % textList.length);
                setTextOpacity(1);
                setCurrentWord(0);
            }, 3000);
        }, textDuration);

        return () => clearInterval(interval);
    }, []);

    // Every {{wordFrequency}} milliseconds shows another word
    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWord((prevWordIndex) => {
                if (prevWordIndex < textList[currentSentence].split(' ').length - 1) {
                    return prevWordIndex + 1;
                } else {
                    return prevWordIndex;
                }
            });
        }, wordFrequency);

        return () => clearInterval(wordInterval);
    }, [currentSentence]);

    return (<>
        {textList[currentSentence].split(' ').map((word, index) => (
            <span
                key={index}
                style={{
                    opacity: currentWord >= index ? textOpacity : 0,
                    transition: textOpacity === 0 ? `opacity 1s linear ${index * .2}s` : `opacity 1s`,
                }}
            >
                {`${word} `}
            </span>
        ))}
    </>);
}

export default RotatingTextFade;
