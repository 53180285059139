// Timeline event info modal for CU Summer STEM - Robotics Crash Course

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const RoboticsCrashCourseInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'C++', size: 'md' },
            { name: 'Arduino', size: 'md' },
            { name: 'Git', size: 'md' }
        ]
    ];

    // The body text to display
    const bodyText = [
        `
            During the summer of 2020, I returned to the Cooper Union Summer STEM program, 
            co-teaching their Robotics Crash Course.
        `,

        <>
            Due to COVID, the usual six-week course was split into <Bold>two three-week sessions
            </Bold> of roughly <Bold>20 high-school students</Bold> in order to allow more people
            to participate. Necessary components were shipped to each student's home and classes
            were taught over Zoom.
        </>,

        <>
            Throughout these sessions, my co-workers and I directed the students in <Bold>
            assembling a vehicle</Bold> containing an arduino and various sensors, and
            taught the students the necessary software skills to <Bold>program their
            vehicle to drive autonomously</Bold> while avoiding obstacles.
        </>,

        <>
            I <Bold>created presentations and taught classes</Bold> on C++, Arduino, Git,
            and more, and I <Bold>met with students</Bold> to assist them in building their
            vehicle and debugging issues.
        </>,

        `
            While teaching over Zoom had its challenges, it was a rewarding experience. I, 
            along with my co-workers, were able to cause students who began the summer with 
            no engineering experience to leave the course having accomplished an amazing project 
            on their own and with a newfound interest in the field of engineering. Teaching this 
            course allowed me to review and master the fundamentals of Arduino and C++, while 
            researching new topics in order to answer difficult questions asked by students.
        `
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Robotics Teacher"
                    subtitle="Cooper Union Summer STEM"
                    dateString="Summer 2020"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default RoboticsCrashCourseInfoModal;
