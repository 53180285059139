// Timeline event info modal for Mixx Project

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from "../../components";

import { GithubIconButton } from "../../../../../../../components";
import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const MixxInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'TypeScript', size: 'md' },
            { name: 'React', size: 'md' }
        ],
        [
            { name: 'Python', size: 'md' },
            { name: 'Flask', size: 'md' },
            { name: 'MongoDB', size: 'md' }
        ]
    ];

    // The body text to display
    const bodyText = [
        <>
            <Bold>Mixx was a passion project</Bold> of mine built with JavaScript, 
            React, Python, Flask, and MongoDB that spun out of a term project for my 
            Database course at Cooper Union. Mixx is a web-app to enhance every 
            home-bartender's experience. Users can create an account to track their 
            current inventory, receiving <Bold>cocktail suggestions based on what they 
            have on hand</Bold> and <Bold>ingredient suggestions for their next purchase</Bold>. 
            Users can save cocktails for later or rate cocktails to receive recommendations. 
            Even without creating an account, users can browse the entire database of 
            cocktails and ingredients, viewing recipes and step-by-step instructions.
        </>,

        <>
            The site offers <Bold>various features including</Bold>: account creation & 
            modification, inventory storage, viewing of available cocktails, cocktail 
            favoriting (saving), cocktail rating, cocktail recommendations, ingredient 
            purchase recommendations, cocktail & ingredient database exploration, cocktail 
            instructions & recipes, and various filters / search options. The site is split 
            into two sections: one which is only accessible with an account and one that can 
            be viewed by anyone.
        </>,

        <>
            I <Bold>wrote Python scripts to scrape the cocktail data</Bold> from 
            various sources, and then clean and normalize it.
        </>,

        <>
            The requirements for this project were simply to build a UI that interacts 
            with a database. While I could have stopped there, I decided to use this project 
            as an opportunity to <Bold>delve more into web development and to build something 
            I would actually want to use</Bold>. Working on this project refined by React 
            knowledge and allowed be to dive into a framework I hadn't used before, Python Flask.
        </>
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Mixx"
                    subtitle="A Home-Bartender's Best Friend"
                    dateString="Fall 2021"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
                <GithubIconButton buttonUrl="https://github.com/alorber/Mixx" />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default MixxInfoModal;
