// The modal displayed for the Stealth Startup timeline item

import {
    Bold,
    ModalBodyText,
    ModalHeaderText,
    ModalSkillsLayout,
    TimelineEventModalWrapper
} from '../../components'

import React from "react";
import { Skill } from "../../../../../../../types";
import { TimelineEventInfoModalProps } from '../../types';
import { VStack } from "@chakra-ui/react";

const StealthStartupInfoModal = ({
    isOpen,
    onClose
}: TimelineEventInfoModalProps) => {

    // The skill blocks to display
    const skills: Skill[][] = [
        [
            { name: 'JavaScript', size: 'md' },
            { name: 'TypeScript', size: 'md' },
            { name: 'React', size: 'md' },
            { name: 'Node', size: 'md' }
        ],
        [
            { name: 'Express', size: 's' },
            { name: 'Chakra', size: 's' },
            { name: 'PostgreSQL', size: 's' },
            { name: 'GraphQL', size: 's' },
            { name: 'Docker', size: 's' },
            { name: 'Postman', size: 's' },
        ]
    ];

    // The body text to display
    const bodyText = [
        <>
            From January to October of 2023, I was contracted as the Founding Engineer 
            for a New-York-based startup, <Bold>building their e-commerce vision from the 
            ground up</Bold>.
        </>,

        <>
            I <Bold>collaborated with the CEO</Bold> to determine project requirements 
            and a roadmap for release, I <Bold>made informed decisions</Bold> on the 
            choices of technologies and implementations, and I worked with the company's 
            patent lawyer to <Bold>secure a preliminary patent</Bold> on my software.
        </>,

        <>
            As the <Bold>sole engineer</Bold>, I <Bold>architected, designed, and built 
            the entire system</Bold>, including two Shopify apps, a core service backend API, 
            a web-app, and a PostgresSQL database with a heavy focus on reusability and 
            upgradeability in the future, as well as ease of access for future developers.
        </>,

        <>
            I <Bold>deployed all sections</Bold> of the product using AWS, Docker, and Fly.io, 
            and I <Bold>onboarded the company's initial client</Bold>.
        </>
    ];

    return (
        <TimelineEventModalWrapper
            isOpen={isOpen} onClose={onClose}
            modalHeader={
                <ModalHeaderText
                    title="Founding Full Stack Engineer"
                    subtitle="Stealth Startup"
                    dateString="Jan. - Oct. 2023"
                />
            }
        >
            <VStack>
                <ModalSkillsLayout skillRows={skills} />
                <ModalBodyText bodyText={bodyText} />
            </VStack>
        </TimelineEventModalWrapper >
    )
}

export default StealthStartupInfoModal;
